import { StoryblokRichtext, render } from 'storyblok-rich-text-react-renderer';

type PageTitleProps = {
  headline: string;
  subtitle?: string;
  description?: StoryblokRichtext;
};

const PageTitle = ({ headline, subtitle, description }: PageTitleProps) => {
  return (
    <div className='flex flex-col items-center mx-auto w-full'>
      <h1 className='style-10-mobile text-center tablet:style-10 phone:text-center mt-[131px] mb-8 uppercase'>
        {headline}
      </h1>
      <h2 className='text-[20px] tablet:text-[36px] font-respira text-center mb-8'>{subtitle}</h2>
      <div className='mb-8 text-center prose mt-6 style-1-mobile phone:style-1 phone:text-center max-w-[672px]'>
        <div>{description ? render(description) : null}</div>
      </div>
    </div>
  );
};

export default PageTitle;
