import { StoryblokAsset } from '../types/StoryblokAsset';
import { StoryblokLink } from '../types/StoryblokLink';
import { storyblokEditable } from '@storyblok/react';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { locales, defaultLocale } from '../translations/i18nConfig';

export type MenuLinkProps = {
  blok: {
    link_text: string;
    link: StoryblokLink;
    description?: string;
    icon: StoryblokAsset;
  };
  index: number;
};

const formatLink = (link: StoryblokLink, locale: string) => {
  if (!link) return '#';

  const baseUrl = `/${link.cached_url.replace(/^(\/|\/$)/g, '')}`;

  switch (link.linktype) {
    case 'story':
      if (locales.some((loc) => baseUrl.startsWith(`/${loc}`))) {
        return baseUrl;
      }
      return locale === defaultLocale ? baseUrl : `/${locale}${baseUrl}`;
    case 'url':
      return link.url;
    default:
      return link.url || '#';
  }
};

export const MenuLinkFooter = ({ blok, index }: MenuLinkProps) => {
  const location = useLocation(); // Utiliser useLocation pour obtenir la locale actuelle

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const locale = locales.includes(pathSegments[0])
    ? pathSegments[0]
    : defaultLocale;

  const formattedUrl = formatLink(blok.link, locale);

  return (
    <Link
      sx={{
        fontSize: {
          xs: '16px', // Mobile
          md: '16px', // Desktop
        },
        color: '#FFFFFF',
        fontFamily: 'Soehne Mono',
        fontWeight: 500,
        textDecoration: 'none', // Remove underline
        '&:hover': {
          textDecoration: 'underline', // Add underline on hover
        },
      }}
      href={formattedUrl}
      {...storyblokEditable(blok)}
      className={`${index === 0 ? '' : ''}`}
    >
      {blok.link_text}
    </Link>
  );
};
