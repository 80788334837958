// components/SkeletonLoader.tsx
import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SkeletonLoader: React.FC = () => {
  return (
    <div className='flex flex-col justify-between h-screen container max-w-container mx-auto'>
      <div>
        <Skeleton
          variant='rectangular'
          width='100%'
          height={118}
          animation='wave'
        />
        <div className='flex flex-col justify-between gap-10 mt-10'>
          <Skeleton variant='text' width='100%' animation='wave' />
          <Skeleton variant='text' width='90%' animation='wave' />
          <Skeleton variant='text' width='80%' animation='wave' />
          <Skeleton variant='text' width='70%' animation='wave' />
          <Skeleton variant='text' width='60%' animation='wave' />
          <Skeleton variant='text' width='50%' animation='wave' />
        </div>
      </div>

      <Skeleton
        variant='rectangular'
        width='100%'
        height={331}
        animation='wave'
      />
    </div>
  );
};

export default SkeletonLoader;
