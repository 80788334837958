import React from 'react';
import Button from './Button';
import { StoryblokLink } from '../types/StoryblokLink';

type JobCardProps = {
  title: string;
  subtitle: string;
  description: string;
  link_text: string;
  link: StoryblokLink;
};

const JobCard: React.FC<JobCardProps> = ({
  title,
  subtitle,
  description,
  link_text,
  link,
}) => {
  return (
    <div className='flex flex-col items-center text-center p-4'>
      <h3 className='text-center phone:text-center style-16 !text-[2rem] phone:!text-[3rem] uppercase mb-2'>
        {title}
      </h3>
      <div className='min-h-[200px]'>
        <h2 className='style-16-mobile font-respira text-center mb-4'>
          {subtitle}
        </h2>
        <div className='mb-4 text-center phone:text-center  style-14-mobile tablet:style-14 max-w-[381px]'>
          {description}
        </div>
      </div>
      <Button label={link_text} customvariant='filled' storyblokLink={link} />{' '}
    </div>
  );
};

export default JobCard;
