import { StoryblokLink } from '../types/StoryblokLink';
import { Typography } from '@mui/material';
import Link from './base/Link';
import { MenuLinkFooter } from './MenuLinkFooter';

import { ReactComponent as LinkedInIcon } from '../assets/social/linkedin.svg';
import { ReactComponent as FacebookIcon } from '../assets/social/facebook.svg';
import { ReactComponent as TwitterIcon } from '../assets/social/twitter.svg';
import { ReactComponent as InstagramIcon } from '../assets/social/instagram.svg';

type LinkType = StoryblokLink;

interface FooterContent {
  component: string;
  title: string;
  facebook: LinkType;
  twitter: LinkType;
  linkedin: LinkType;
  instagram: LinkType;
  additional_links: { label: string; url: LinkType }[];
  address: string;
  phone_number: string;
}

interface FooterProps {
  config?: {
    footer_menu: FooterContent[];
  };
}

const Footer = ({ config }: FooterProps) => {
  if (!config?.footer_menu) {
    return null;
  }

  const footerContent = config.footer_menu.find(
    (item: any) => item.component === 'footer_content'
  ) as FooterContent;

  if (!footerContent) {
    console.error('footer_content not found');
    return null;
  }

  const {
    title,
    facebook: facebookLink,
    twitter: twitterLink,
    linkedin: linkedinLink,
    instagram: instagramLink,
    address,
    phone_number,
  } = footerContent;

  const additionalLinksArray = config.footer_menu.filter(
    (item: any) => item.component === 'additional_links'
  );

  return (
    <footer
      className='bg-overlay-menu-leistungen h-[331px] pt-[45px] tablet:pt-[74px] pb-[24px] flex flex-col justify-between relative'
      aria-labelledby='footer-heading'
    >
      <div className='max-w-container container mx-auto'>
        <div className='flex flex-col tablet:flex-row  tablet:justify-between gap-2 tablet:gap-10'>
          <Typography
            component='div'
            sx={{
              fontFamily: 'Respira Black',
              color: '#C6C6C6',
              fontSize: {
                xs: '40px', // Mobile
                md: '60px', // Desktop
              },
            }}
          >
            {title}
          </Typography>
          <div className='flex flex-col text-left'>
            {additionalLinksArray?.map((nestedBlok: any, index: number) => (
              <MenuLinkFooter
                index={index}
                blok={nestedBlok}
                key={nestedBlok._uid}
              />
            ))}
          </div>
        </div>
        <div className='flex items-center gap-[64px] absolute bottom-4 tablet:absolute tablet:bottom-4'>
          <div className='flex justify-center items-center gap-8'>
            {facebookLink?.cached_url && (
              <Link
                href={facebookLink.cached_url}
                target='_blank'
                icon={<FacebookIcon />}
              />
            )}

            {twitterLink?.cached_url && (
              <Link
                href={twitterLink.cached_url}
                target='_blank'
                icon={<TwitterIcon />}
              />
            )}

            {linkedinLink?.cached_url && (
              <Link
                href={linkedinLink.cached_url}
                target='_blank'
                icon={<LinkedInIcon />}
              />
            )}

            {instagramLink?.cached_url && (
              <Link
                href={instagramLink.cached_url}
                target='_blank'
                icon={<InstagramIcon />}
              />
            )}
          </div>
          <Typography
            component='div'
            className='hidden tablet:flex'
            variant='body2'
            sx={{
              color: '#C6C6C6',
              gap: '20px',
              flexWrap: 'wrap',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <span className='style-4-mobile tablet:style-4'>{address}</span>
            <a
              href={`tel:${phone_number}`}
              className='style-4-mobile tablet:style-4'
            >
              {phone_number}
            </a>
          </Typography>
        </div>

        <div className='absolute right-4 bottom-4 h-full flex items-center rotate-180 tablet:hidden z-10'>
          <Typography
            component='div'
            variant='body2'
            sx={{
              color: '#C6C6C6',
              writingMode: 'vertical-lr',
              textOrientation: 'mixed',
              fontFamily: 'Söhne Mono',
              marginBottom: '150px',
              fontSize: {
                xs: '10px', // Mobile
                md: '14px', // Desktop
              },
            }}
          >
            <div>
              <span className='style-4-mobile phone:style-4 text-[8px] phone:text-[8px]'>
                {address}
              </span>
            </div>
            <div>
              <a
                href={`tel:${phone_number}`}
                className='style-4-mobile phone:style-4 text-[8px] phone:text-[8px]'
              >
                {phone_number}
              </a>
            </div>
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
