import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLocale } from './i18nConfig'; // Importer le fichier de config
import en from '../translations/en.json'; // Traductions en anglais
import de from '../translations/de.json'; // Traductions en allemand

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    de: { translation: de },
  },
  lng: defaultLocale,
  fallbackLng: defaultLocale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
