import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

type FooterMenuProps = {
  blok: {
    links: {
      link: string;
      link_text: string;
      [key: string]: any;
    }[];
  };
};

const FooterMenu = ({ blok }: FooterMenuProps) => {
  return (
    <div {...storyblokEditable({ blok })}>
      {blok.links.map((nestedBlok, index) => (
        <StoryblokComponent
          footer={true}
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </div>
  );
};
export default FooterMenu;
