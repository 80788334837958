import { storyblokEditable } from '@storyblok/react';
import { StoryblokLink } from '../../types/StoryblokLink';
import Button from '../Button';

import backgroundImage from '../../assets/images/copy_center_background.svg';

type JobBannerProps = {
  blok: {
    header: string;
    button_text: string;
    button_link: StoryblokLink;
    backgroundColor: string;
  };
};

const CopyCenter = ({ blok }: JobBannerProps) => {
  return (
    <div
      className='relative max-w-container container mx-auto text-center mt-[70px] tablet:mt-[130px] mb-[63px] z-0'
      {...storyblokEditable(blok)}
      style={{ backgroundColor: blok.backgroundColor }}
    >
      <div className='relative z-10 pt-8 pb-[47px] bg-flieder'>
        {/* Background Image Overlay */}
        <div
          className='absolute inset-0 z-0'
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: '50%', // Adjust size as needed
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.2, // Adjust opacity as needed
            pointerEvents: 'none',
          }}
        ></div>

        <h3 className='text-heading-lg text-newspaper-main mb-5 style-6-mobile phone:style-6 text-center phone:text-center'>
          {blok.header}
        </h3>
        <Button
          label={blok.button_text}
          customvariant='filled'
          storyblokLink={blok.button_link}
        />
      </div>
    </div>
  );
};

export default CopyCenter;