import { storyblokEditable } from '@storyblok/react';
import PageTitle from '../base/PageTitle';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

type WorkingAtAEProps = {
  blok: {
    _uid: string;
    _editable: string;
    headline: string;
    subtitle: string;
    description: StoryblokRichtext;
  };
};

const WokingAtAE = ({ blok }: WorkingAtAEProps) => {
  return (
    <div {...storyblokEditable(blok)} className='py-8'>
      <div className='container mx-auto max-w-container'>
        <PageTitle
          headline={blok.headline}
          subtitle={blok.subtitle}
          description={blok.description}
        />
      </div>
    </div>
  );
};

export default WokingAtAE;
