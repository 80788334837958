import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import type { BlokComponent } from '../types/BlokComponent';
import type { SbBlokData } from '@storyblok/react';
import imageEightBars from '../assets/images/bars-eight.svg';
import imageSixBars from '../assets/images/bars-six.svg';
import imageFourBars from '../assets/images/bars-four.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';


interface StoryblokImage {
  id: number;
  alt: string;
  name: string;
  focus: string;
  title: string;
  filename: string;
  copyright: string;
  fieldtype: string;
}

const Page = ({
  blok,
}: BlokComponent<{
  body: SbBlokData[];
  backgroundColor?: string;
  backgroundImage?: StoryblokImage;
  hasBackgroundBars?: boolean;
  backgroundBars?: string;
}>) => {
  const backgroundColor = blok.backgroundColor ? blok.backgroundColor : '#F0F0EF';

  // const SetBackgroundImages = () => {
  //   const { width } = useWindowDimensions();
  //   let url = '';

  //   if (blok.backgroundImage) {
  //     url = url + `url(${blok.backgroundImage.filename})`
  //   }
  //   if (blok.hasBackgroundBars) {
  //     width > 768 ? url = url + `, url(${imageEightBars})` : url = url + `, url(${imageFourBars})`
  //   }
  //   return url
  // };

  const SetBackgroundImages = () => {
    const { width } = useWindowDimensions();
    let url = '';

    if (blok.backgroundImage) {
      url = url + `url(${blok.backgroundImage.filename})`
    }
    if (blok.backgroundBars === 'eight') {
      width > 490 ? url = url + `, url(${imageEightBars})` : url = url + `, url(${imageFourBars})`
    } else if (blok.backgroundBars === 'six') {
      width > 490 ? url = url + `, url(${imageSixBars})` : url = url + `, url(${imageFourBars})`
    }
    return url
  };

  const style: React.CSSProperties = {
    backgroundColor: backgroundColor,
    backgroundImage: SetBackgroundImages(),
    backgroundSize: '127%, auto',
    backgroundPosition: 'center, center',
    backgroundPositionY: '160px, 303px',
    backgroundRepeat: 'no-repeat, no-repeat',
    minHeight: '100vh', // Ensure the page takes at least the full viewport height
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div {...storyblokEditable(blok)} style={style}>
      {blok.body.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  );
};

export default Page;