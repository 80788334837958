import { useState } from 'react';
import { StoryblokRichtext, render } from 'storyblok-rich-text-react-renderer';
import Divider from '@mui/material/Divider';

type ServicesItemProps = {
  headline?: string;
  copy?: string;
  details?: StoryblokRichtext;
};


const ServicesItem = ({ headline, copy, details }: ServicesItemProps) => {
  const [isVisible, setVisible] = useState(false);


  const toggleVisibility = () => {
    setVisible(!isVisible);
  };

  return (
    <div>
      <Divider className="bg-black" />
      <p className="w-[100%] tablet:w-[227px] py-[8px] tablet:style-17 style-17-mobile hyphenation [word-spacing:9999999px]">{headline}</p>
      <Divider className="bg-black" />
      <p className="tablet:style-1 style-1-mobile mt-2">{copy}</p>
      <p onClick={toggleVisibility} className="w-fit tablet:hidden style-7 !leading-none cursor-pointer">+</p>
      <div className={`${isVisible ? "flex" : "hidden"} details tablet:hidden tablet:group-hover:flex z-100 fixed top-0 left-0 h-lvh w-lvw phone:w-[94vw] tablet:w-[52vw] desktop:w-[44vw] bg-black bg-opacity-85 text-white`}>
        <p onClick={toggleVisibility} className="tablet:hidden absolute w-fit top-20 right-4 style-7 !leading-none cursor-pointer z-200 opacity-100 text-flieder">
          &#x2715;
        </p>
        <p className="vertical-rl absolute bottom-2 right-2 desktop:top-28 desktop:left-7 desktop:bottom-[initial] desktop:right-[initial] style-5-mobile desktop:style-5 tablet:!text-[1rem] !text-[0.75rem]">{headline}</p>
        <div className="desktop:self-center desktop:relative ml-8 tablet:left-20 pt-48 tablet:pt-32 style-21-mobile desktop: style-21">{details ? render(details) : null}</div>
      </div>

    </div >
  )
};


export default ServicesItem;



