import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { storyblokEditable } from '@storyblok/react';
import ShowcaseItem from '../ShowcaseItem';
import { StoryblokLink } from '@/types/StoryblokLink';

type ImageType = {
  _editable: string;
  _uid: string;
  component: string;
  image: {
    alt: string;
    filename: string;
  };
  link?: StoryblokLink;
};

type ShowCaseProps = {
  blok: {
    headline: string;
    images: ImageType[];
  };
};

const Showcase: React.FC<ShowCaseProps> = ({ blok }) => {
  const sliderRef = useRef<Slider | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const clearExistingInterval = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      const { left, right } = e.currentTarget.getBoundingClientRect();
      const middle = (left + right) / 2;
      clearExistingInterval();
      if (e.clientX < middle) {
        const id = setInterval(() => {
          sliderRef.current?.slickPrev();
        }, 1500); // Increase the speed when hovering
        setIntervalId(id);
      } else {
        const id = setInterval(() => {
          sliderRef.current?.slickNext();
        }, 1500); // Increase the speed when hovering
        setIntervalId(id);
      }
    },
    [clearExistingInterval]
  );

  const handleMouseLeave = useCallback(() => {
    clearExistingInterval();
    const id = setInterval(() => {
      sliderRef.current?.slickNext();
    }, 3000); // Slower speed when not hovering
    setIntervalId(id);
  }, [clearExistingInterval]);

  useEffect(() => {
    // Start the slow auto-scroll to the right when the component mounts
    const id = setInterval(() => {
      sliderRef.current?.slickNext();
    }, 3000);
    setIntervalId(id);

    return () => {
      clearInterval(id);
    };
  }, []);

  const settings = useMemo(
    () => ({
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '80px',
      slidesToShow: 3,
      autoplay: false, // Disable the default autoplay
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '60px',
          },
        },
      ],
    }),
    []
  );

  return (
    <button
      className='pb-20 bg-white relative'
      {...storyblokEditable(blok)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <style>
        {`
          .half-grey-background {
            position: relative;
          }
          .half-grey-background::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: white;
            z-index: -1;
          }
          .middle-border {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 1px;
            background-color: red;
            z-index: 10;
          }
        `}
      </style>
      <div className='max-w-container container mx-auto'>
        <h2 className='style-3-mobile tablet:style-3 text-left mb-8 lowercase'>
          {blok.headline ?? 'Showcase'}
        </h2>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {blok.images.map((item) => (
          <div
            key={item._uid}
            className='px-2 desktop:px-4 relative bg-white'
            style={{ padding: '0 15px', width: '100%', height: 'auto' }}
          >
            <ShowcaseItem
              imageSrc={item.image.filename}
              title={item.image.alt}
              link={item.link}
            />
          </div>
        ))}
      </Slider>
    </button>
  );
};

export default Showcase;
