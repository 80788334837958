import React from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import Divider from '@mui/material/Divider';
import { StoryblokAsset } from '../../types/StoryblokAsset';
import HeaderImageAliensExist from '../../assets/wortmarke_aliens_test_png_02.png';
import backgroundImage from '../../assets/images/stage_background.svg';
import FullWidthTitle from '../base/FullWidthTitle';

type StageProps = {
  blok: {
    headerImage?: StoryblokAsset;
    columns: any[];
  };
};

const Stage = ({ blok }: StageProps) => {
  const columnsCount = blok.columns.length;

  const getGridClass = (columns: number) => {
    switch (columns) {
      case 1:
        return 'tablet:grid-cols-1';
      case 2:
        return 'tablet:grid-cols-2';
      case 3:
        return 'tablet:grid-cols-3';
      case 4:
        return 'tablet:grid-cols-4';
      case 5:
        return 'tablet:grid-cols-5';
      default:
        return 'tablet:grid-cols-1';
    }
  };

  const gridClass = `grid grid-cols-1 ${getGridClass(
    columnsCount
  )} gap-6 place-items-center items-start mx-auto max-w-container container pt-[55px] pb-[40px]`;

  return (
    <div
      {...storyblokEditable(blok)}
      className='pt-15 tablet:pb-15 bg-newspaper-background'
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={`${gridClass} hidden tablet:grid`}>
        {blok.columns.map((column) => (
          <Divider
            key={column._uid}
            className='w-full text-newspaper-decorative'
          />
        ))}
      </div>

      <FullWidthTitle
        fileName={blok.headerImage?.filename ?? HeaderImageAliensExist}
        alt={blok.headerImage?.alt ?? 'Aliens Exist'}
      />

      <div className={gridClass}>
        {blok.columns.map((column) => (
          <StoryblokComponent blok={column} key={column._uid} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(Stage);
