import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import Divider from '@mui/material/Divider';
import BenefitCard from '../BenefitCard';

type BenefitsProps = {
  blok: {
    _uid: string;
    _editable: string;
    headline: string;
    benefits: {
      image: {
        filename: string;
      };
      title: string;
      description: string;
    }[];
  };
};

const Benefits: React.FC<BenefitsProps> = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className='container mx-auto max-w-container pb-6 pt-6 mt-12'
    >
      <div className='flex justify-center'>
        <Divider className="w-[55%] tablet:w-[628px] !ml-1 bg-black" />
      </div>
      <h2 className='style-19-mobile phone:style-19 uppercase mb-10 mt-7'>
        {blok.headline}
      </h2>
      <div className='flex flex-wrap justify-center gap-5 tablet:gap-16 '>
        {blok.benefits.map((benefit, index) => (
          <BenefitCard
            key={index}
            image={benefit.image}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
      </div>
      <div className='flex justify-center'>
        <Divider className="w-[55%] tablet:w-[628px] !mt-12 !ml-1 bg-black" />
      </div>
    </div>
  );
};

export default Benefits;
