import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import { StoryblokRichtext, render } from 'storyblok-rich-text-react-renderer';

type RichTextProps = {
  blok: {
    content: StoryblokRichtext;
    alignment: 'center' | 'left';
    text_alignment: 'center' | 'left';
    max_width: number;
  };
};

const GenericRichText: React.FC<RichTextProps> = ({ blok }) => {
  const justifyClass =
    blok.alignment === 'center' ? 'justify-center' : 'justify-left';
  const textAlignClass =
    blok.text_alignment === 'center' ? 'text-center' : 'text-left';

  return (
    <div
      {...storyblokEditable(blok)}
      className={`container mx-auto max-w-container style-1-small-phone tablet:style-1-mobile desktop:style-1 my-8 flex ${justifyClass}`}
    >
      <div
        className={`${textAlignClass}`}
        style={{ maxWidth: `${blok.max_width}px` }}
      >
        {render(blok.content)}
      </div>
    </div>
  );
};

export default GenericRichText;
