import React, { useRef, useEffect } from 'react';

const NotFound: React.FC = () => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (textRef.current) {
        const scrollPosition = window.scrollY;
        textRef.current.style.transform = `translateY(-${scrollPosition}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleBackClick = () => {
    window.location.href = '/';
  };

  return (
    <div
      className='relative h-screen overflow-hidden py-12'
      style={{ backgroundColor: '#A994FF' }}
    >
      <div className='flex flex-col items-center justify-center h-screen text-center'>
        <div
          className='relative z-20 flex items-center justify-center w-full text-[270px] phone:text-[300px] tablet:text-[500px] desktop:text-[1142px] style-10'
          style={{
            color: '#000',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '138%',
          }}
        >
          404
          <div className='absolute inset-0 flex items-center justify-center text-[60px] phone:text-[60px] tablet:text-[70px] desktop:text-[95px] style-13'>
            <div
              ref={textRef}
              style={{
                color: 'black',
                writingMode: 'vertical-lr',
                textOrientation: 'mixed',
                lineHeight: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className='-z-10'>ERRORERRORERRORERRORERRORERRORERRORERRORERRORERROR</span>
              <span
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  writingMode: 'vertical-lr',
                  textOrientation: 'mixed',
                  fontSize: 'inherit',
                }}
                role='button'
                onClick={handleBackClick}
              >
                BACK
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
