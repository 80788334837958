import { StoryblokRichtext, render } from 'storyblok-rich-text-react-renderer';
import imageSixBars from '../assets/images/bars-six.svg';

type ProjectDetailsProps = {
  blok: {
    title?: string;
    subtitle?: string;
    skills?: StoryblokRichtext;
    content?: StoryblokRichtext;
    image?: {
      alt?: string;
      filename?: string;
    };
    gallery?: {
      component: string;
      image: {
        alt?: string;
        filename?: string;
      };
      _editable: string;
      _uid: string;
    }[];
  };
};

const ProjectDetails = ({ blok }: ProjectDetailsProps) => {
  const style: React.CSSProperties = {
    backgroundImage: `url(${imageSixBars})`,
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <section className="projectDetails mb-[114px]" style={style}>
      <div className='relative'>
        {blok?.image?.filename && (
          <div className='w-full h-auto overflow-hidden mt-14'>
            <img
              className='w-full h-full object-contain'
              alt={blok.image?.alt ?? 'Project image'}
              src={`${blok.image?.filename}`}
            />
          </div>
        )}
      </div>
      <div className='container mx-auto flex flex-col px-5'>
        <h1 className='style-10-mobile text-center phone:style-10 phone:text-center mt-[105px] mb-8 uppercase'>
          {blok?.title}
        </h1>
        <h2 className='style-12-mobile font-respira text-center mb-8'>
          {blok?.subtitle}
        </h2>
        <div className='flex flex-col tablet:flex-row gap-[29px] tablet:ml-[120px] mb-8 mt-12 text-left prose'>
          {blok?.skills && (
            <div className='phone:min-w-[175px] tablet:w-[188px]'>
              <h3 className='uppercase style-14-mobile tablet:style-14 !font-bold'>Skills</h3>
              <div className='style-1-mobile tablet:style-1'>
                {render(blok.skills)}
              </div>
            </div>
          )}
          {blok?.content && <div className='style-1-mobile tablet:style-1 max-w-[550px] text-left'>{render(blok.content)}</div>}
        </div>
      </div>
      <div className='w-full'>
        {blok?.gallery?.map((item) => (
          <div key={item._uid} className='w-full h-auto overflow-hidden'>
            <img
              className='w-full h-auto object-contain'
              alt={item.image.alt ?? 'Gallery image'}
              src={item.image.filename}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProjectDetails;
