import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import JobCard from '../JobCard';
import { storyblokEditable } from '@storyblok/react';
import { StoryblokLink } from '../../types/StoryblokLink';

type JobListProps = {
  blok: {
    headline: string;
    jobs: {
      title: string;
      subtitle: string;
      description: string;
      link_text: string;
      link: StoryblokLink;
    }[];
  };
};

const JobList: React.FC<JobListProps> = ({ blok }) => {
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(3, blok.jobs.length), // Show a maximum of 3 slides or less if fewer jobs
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, blok.jobs.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className='container mx-auto max-w-container pt-6 pb-48 text-center'
    >
      <h2 className='style-19-mobile phone:style-19 uppercase'>
        {blok.headline}
      </h2>
      {blok.jobs.length > 1 ? (
        <>
          <Slider ref={sliderRef} {...settings} className='mt-8'>
            {blok.jobs.map((job, index) => (
              <div key={index} className='px-2'>
                <JobCard
                  title={job.title}
                  subtitle={job.subtitle}
                  description={job.description}
                  link_text={job.link_text}
                  link={job.link}
                />
              </div>
            ))}
          </Slider>
          <div className='flex justify-center mt-8 gap-x-16'>
            <ArrowBackIcon onClick={goToPrevious} className='cursor-pointer !text-[2.5rem]' />
            <ArrowForwardIcon onClick={goToNext} className='cursor-pointer !text-[2.5rem]' />
          </div>
        </>
      ) : (
        blok.jobs.map((job, index) => (
          <div key={index} className='px-2 mt-8'>
            <JobCard
              title={job.title}
              subtitle={job.subtitle}
              description={job.description}
              link_text={job.link_text}
              link={job.link}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default JobList;