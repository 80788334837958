import { useStoryblok } from '@storyblok/react';
import { useTranslation } from 'react-i18next';

const useStoryblokConfig = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const settings = useStoryblok('settings', {
    version: 'draft',
    language: locale === 'de' ? '' : locale, // Not passing an explicit locale for 'de'
  });

  return settings ? settings.content : null;
};

export default useStoryblokConfig;
