import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

type HeaderMenuProps = {
  blok: {
    links: {
      link: string;
      link_text: string;
    }[];
  };
};

const HeaderMenu = ({ blok }: HeaderMenuProps) => {
  return (
    <div
      className='hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10'
      {...storyblokEditable({ blok })}
    >
      {blok.links.map((nestedBlok, index) => (
        <StoryblokComponent key={index} blok={nestedBlok} />
      ))}
    </div>
  );
};

export default HeaderMenu;
