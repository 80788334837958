import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ListItem, ListItemText } from '@mui/material';
import { BlokComponent } from '../types/BlokComponent';
import { StoryblokAsset } from '../types/StoryblokAsset';
import { StoryblokLink } from '../types/StoryblokLink';
import { storyblokEditable } from '@storyblok/react';
import { locales, defaultLocale } from '../translations/i18nConfig';

export type MenuLinkProps = {
  link_text: string;
  link: StoryblokLink;
  description?: string;
  icon: StoryblokAsset;
};

// Function to get the localized link
const getLocalizedLink = (link: StoryblokLink, locale: string) => {
  if (!link) return '#';

  const baseLink = link.cached_url.startsWith('/')
    ? link.cached_url
    : `/${link.cached_url}`;

  // If the URL is an anchor or mailto link, return it as is
  if (
    link.cached_url.startsWith('#') ||
    link.cached_url.startsWith('mailto:')
  ) {
    return link.cached_url;
  }

  // Check if the URL already contains a locale
  if (locales.some((loc) => baseLink.startsWith(`/${loc}`))) {
    return baseLink; // Return the URL as is if it already contains a locale
  }

  // Add the locale to the URL
  return locale === defaultLocale ? baseLink : `/${locale}${baseLink}`;
};

const MenuLink = ({ blok }: BlokComponent<MenuLinkProps>) => {
  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const locale = locales.includes(pathSegments[0])
    ? pathSegments[0]
    : defaultLocale;

  const normalizedLink = getLocalizedLink(blok.link, locale);

  const basePathWithoutAnchor = location.pathname;
  const isActive =
    basePathWithoutAnchor === normalizedLink ||
    location.hash === normalizedLink;

  return (
    <ListItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Link
        to={normalizedLink}
        {...storyblokEditable(blok)}
        className={`relative top-1 text-copy-m cursor-pointer group ${
          isActive ? 'activeLink' : ''
        }`}
        style={{
          textDecoration: 'none',
          color: isActive ? '#A994FF' : '#ffffff',
        }} // Active link color
      >
        <ListItemText
          primary={blok.link_text}
          primaryTypographyProps={{
            sx: {
              marginRight: { md: '110px' },
              color: 'inherit', // Inherit color from the parent link
              textAlign: 'right',
              fontFamily: 'Söhne, sans-serif',
              fontSize: { xs: '82.95px', sm: '200px', md: '280.139px' },
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '80%',
              '&:hover': {
                color: '#A994FF',
              },
            },
          }}
        />
        <span
          className={`absolute -bottom-1 left-0 h-1 transition-all ${
            isActive ? 'w-full' : 'w-0'
          } group-hover:w-full`}
        ></span>
      </Link>
    </ListItem>
  );
};

export default MenuLink;
