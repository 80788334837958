import React from 'react';
import OptimizedImage from '../../utils/OptimizedImage';

type FullWidthTitleProps = {
  fileName?: string;
  alt?: string;

};

const FullWidthTitle: React.FC<FullWidthTitleProps> = ({ fileName, alt }) => {
  return (
    <div
      className='w-full pt-[44px] z-50'
      style={{ width: '100vw', boxSizing: 'content-box' }}
    >
      <OptimizedImage
        style={{
          maxWidth: '103.5%',
          marginLeft: '-1.75%',
        }}
        filename={fileName}
        alt={alt}
        fetchpriority='high'
      />
    </div>
  );
};

export default FullWidthTitle;
