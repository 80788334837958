import React from 'react';
import Link from './Link';
import { StoryblokLink } from '@/types/StoryblokLink';


type RichtextContentItem = {
  type: string;
  content?: RichtextContentItem[];
  marks?: any[];
  text?: string;
  attrs?: {
    body?: MenuLinkBlok[];
  };
  _uid?: string;
};

type MenuLinkBlok = {
  component: string;
  link_text: string;
  link: StoryblokLink;
  _editable: string;
  _uid: string;
};

/**
 * Renders a menu link item.
 * @param blok The MenuLinkBlok to render.
 */
const RenderMenuLink = React.memo(({ blok }: { blok: MenuLinkBlok }) => {
  return (
    <Link
      key={blok._uid}
      storyblokLink={blok.link}
      label={blok.link_text}
      hoverColor='#000000'
      textColor='#A994FF'
    />
  );
});

/**
 * Renders a text item with appropriate marks (e.g., bold).
 * @param textItem The RichtextContentItem to render.
 */
const renderText = (textItem: RichtextContentItem) => {
  let text: string | JSX.Element = textItem.text ?? '';
  if (textItem.marks) {
    textItem.marks.forEach((mark) => {
      if (mark.type === 'bold') {
        text = <strong key={textItem._uid}>{text}</strong>;
      }
      // Add other marks here, such as 'italic', 'underline', etc.
    });
  }
  return text;
};

/**
 * Renders the content of a richtext item.
 * @param content The array of RichtextContentItem to render.
 */
const RichtextContent = ({ content }: { content: RichtextContentItem[] }) => {
  return (
    <>
      {content.map((item) => {
        if (item.type === 'blok' && item.attrs?.body) {
          return item.attrs.body.map((innerBlok) => {
            if (innerBlok.component === 'menu_link') {
              return <RenderMenuLink blok={innerBlok} key={innerBlok._uid} />;
            }
            return null;
          });
        } else if (item.type === 'paragraph' && item.content) {
          return (
            <p key={item._uid ?? Math.random().toString(36).substring(2, 11)}>
              {item.content.map((innerItem, index) => {
                if (innerItem.type === 'text') {
                  return (
                    <React.Fragment key={`${item._uid}-${index}`}>
                      {renderText(innerItem)}
                    </React.Fragment>
                  );
                } else if (innerItem.type === 'hard_break') {
                  return <br key={`${item._uid}-${index}`} />;
                } else if (innerItem.type === 'blok' && innerItem.attrs?.body) {
                  return innerItem.attrs.body.map((innerBlok: MenuLinkBlok) => {
                    if (innerBlok.component === 'menu_link') {
                      return (
                        <RenderMenuLink blok={innerBlok} key={innerBlok._uid} />
                      );
                    }
                    return null;
                  });
                }
                return null;
              })}
            </p>
          );
        }
        return null;
      })}
    </>
  );
};

export default RichtextContent;
