import { storyblokEditable } from '@storyblok/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import PageTitle from '../base/PageTitle';
import { useTranslation } from 'react-i18next';

type Image = {
  filename?: string;
};

type Gif = {
  filename?: string;
};

type ProjectCardProps = {
  image: Image;
  gif: Gif;
  title: string;
  description: string;
  slug: string;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  image,
  gif,
  title,
  description,
  slug,
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <Link
      to={`${locale === 'de' ? '' : '/' + locale}/projects/${slug}`}
      className='block my-4'
      aria-label={`Project: ${title}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='relative w-full h-auto overflow-hidden'>
        <picture>
          {isHovered && gif?.filename && (
            <source srcSet={gif?.filename} type='image/gif' />
          )}
          <img
            className='w-full h-auto transition-all duration-300'
            src={image.filename}
            alt={title}
            loading='lazy'
          />
        </picture>
      </div>
      <div className='py-4'>
        <div className='style-13-mobile tablet:style-13 mb-2'>{title}</div>
        <p className='style-14-mobile tablet:style-14'>{description}</p>
      </div>
    </Link>
  );
};

type LookBookProps = {
  blok: {
    _uid: string;
    _editable: string;
    headline: string;
    subtitle: string;
    project_card: ProjectCardProps[];
  };
};

const LookBook: React.FC<LookBookProps> = ({ blok }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = useCallback(() => {
    setShowAll(true);
  }, []);

  const displayedProject = showAll
    ? blok.project_card
    : blok.project_card.slice(0, 6);

  useEffect(() => {
    blok.project_card.forEach((project) => {
      if (project.gif?.filename) {
        const gifImage = new Image();
        gifImage.src = project.gif.filename;
      }
    });
  }, [blok.project_card]);

  const { t } = useTranslation();

  return (
    <div {...storyblokEditable(blok)} className='py-8'>
      <div className='container mx-auto max-w-container text-center'>
        <PageTitle headline={blok.headline} subtitle={blok.subtitle} />
        <div className='grid grid-cols-1 tablet:grid-cols-2 gap-8 justify-center'>
          {displayedProject.map((project, index) => (
            <ProjectCard
              key={project.slug}
              image={project.image}
              gif={project.gif}
              title={project.title}
              description={project.description}
              slug={project.slug}
            />
          ))}
        </div>

        {!showAll && (
          <Button
            onClick={handleShowAll}
            label={t('show_all_projects')}
            className='mt-9'
          />
        )}
      </div>
    </div>
  );
};

export default LookBook;
