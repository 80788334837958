import React from 'react';

type BenefitCardProps = {
  image: {
    filename: string;
  };
  title: string;
  description: string;
};

const BenefitCard: React.FC<BenefitCardProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <div className='flex flex-col items-center text-center max-w-44'>
      <img src={image.filename} alt={title} className='w-20 h-20 mb-4' />
      <h3 className='font-bold phone:font-bold phone:style-14 style-14-mobile uppercase mb-[0.1rem]'>
        {title}
      </h3>
      <div className='text-center phone:text-center phone:style-1 style-1-mobile w-44'>{description}</div>
    </div>
  );
};

export default BenefitCard;
