import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const BackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const currentPage = window.location.pathname;

  return (
    <div className='hidden tablet:block'>
      <Box
        onClick={handleClick}
        sx={{
          display: isVisible ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'fixed',
          right: {
            xs: '0px',
            sm: '10px',
          },
          bottom: '50%',
          cursor: 'pointer',
          zIndex: 1000,
        }}
      >
        {currentPage !== '/team' ? (
          <ArrowUpwardIcon sx={{ color: '#A994FF' }} />
        ) : (
          <ArrowUpwardIcon sx={{ color: '#FFFF' }} />
        )}

        <div
          style={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            color: 'black',
          }}
          className='style-4'
        >
          Back-to-top
        </div>
      </Box>
    </div>
  );
};

export default BackToTop;
