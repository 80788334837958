import React, { useState } from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  Snackbar,
  Alert,
  Link,
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { storyblokEditable } from '@storyblok/react';
import Button from './Button';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import FormData from 'form-data';
import { useTranslation } from 'react-i18next';

type JobOption = {
  value: string;
  label: string;
};

type ApplicationFormBlok = {
  headline: string;
  title: string;
  name_label: string;
  email_label: string;
  phone_label: string;
  job_label: string;
  message_label: string;
  upload_label: string;
  privacy_label: string;
  submit_label: string;
  jobs: JobOption[];
};

type ApplicationFormProps = {
  blok: ApplicationFormBlok;
};

const ApplicationForm: React.FC<ApplicationFormProps> = ({ blok }) => {
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<Record<string, any>>({
    name: '',
    email: '',
    phone: '',
    job: '',
    message: '',
    privacy: false,
    file: null as File | null,
    fileName: '',
    emailError: '',
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (
      file &&
      [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
      ].includes(file.type)
    ) {
      setFormData({
        ...formData,
        file: file,
        fileName: file.name,
      });
    }
  };

  const handleFieldFocus = (fieldName: string) => {
    setMissingFields((prevFields) =>
      prevFields.filter((field) => field !== fieldName)
    );
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      job: '',
      message: '',
      privacy: false,
      file: null,
      fileName: '',
      emailError: '',
    });
  };

  const sendMail = async (data: FormData) => {
    try {
      await axios.post('https://mailer.port4.de/api/v1/mail', data, {
        headers: {
          'Content-Type': 'multipart/mixed',
        },
      });

      setSuccessMessage(t('form.submit_success'));
      resetForm();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(t('form.submit_error')); // Specific error message for Axios errors
      } else {
        setError(t('form.submit_error')); // Generic error message for other errors
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const requiredFields = [
      'email',
      'name',
      'phone',
      'job',
      'message',
      'privacy',
    ];
    const missing = requiredFields.filter((field) => !formData[field]);
    if (missing.length > 0) {
      setMissingFields(missing);
    } else if (!validateEmail(formData.email)) {
      setMissingFields(['email']);
      setFormData((prevData) => ({
        ...prevData,
        emailError: 'Invalid email format',
      }));
    } else {
      setMissingFields([]);
      setFormData((prevData) => ({
        ...prevData,
        emailError: '',
      }));

      const data = new FormData();
      data.append('site', window.location.hostname);
      data.append('subject', `Application for ${formData.job} position`);
      data.append(
        'body',
        `Name: ${formData.name}\nPhone: ${formData.phone}\n${formData.message}`
      );
      data.append('token', 'token');
      data.append('email', formData.email);

      if (formData.file) {
        data.append('attachment', formData.file, formData.file.name);
      }

      await sendMail(data);
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const getFieldBorderColor = (fieldName: string) => {
    return missingFields.includes(fieldName) ? 'red' : 'black';
  };

  const getCheckboxColor = (fieldName: string) => {
    return missingFields.includes(fieldName) ? 'red' : 'black';
  };

  const handleClose = () => {
    setSuccessMessage(null);
    setError(null);
  };

  const { t } = useTranslation();

  return (
    <Container
      maxWidth='sm'
      {...storyblokEditable(blok)}
      className='py-6 tablet:mb-[500px]'
    >
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <h2 className='style-19-mobile phone:style-19 uppercase mb-4'>
          {blok.headline}
        </h2>
        <h2 className='style-1-mobile text-center phone:style-1 phone:text-center mb-12'>
          {blok.title}
        </h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={blok.name_label && blok.name_label}
              variant='outlined'
              required={false}
              name='name'
              value={formData.name}
              onChange={handleChange}
              onFocus={() => handleFieldFocus('name')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0,
                  '& fieldset': {
                    borderColor: getFieldBorderColor('name'),
                    borderWidth: 3,
                  },
                  '&:hover fieldset': {
                    borderColor: getFieldBorderColor('name'),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: getFieldBorderColor('name'),
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '115%',
                  marginTop: '2px',
                },
                '& .MuiOutlinedInput-input': {
                  color: 'black !important', // Ensure label text is black
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '115%',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={blok.email_label && blok.email_label}
              variant='outlined'
              required={false}
              name='email'
              value={formData.email}
              onChange={handleChange}
              onFocus={() => handleFieldFocus('email')}
              inputProps={{ pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }}
              error={Boolean(formData.emailError)}
              helperText={formData.emailError}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0,
                  color: 'black',
                  '& fieldset': {
                    borderColor: getFieldBorderColor('email'),
                    borderWidth: 3,
                  },
                  '&:hover fieldset': {
                    borderColor: getFieldBorderColor('email'),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: getFieldBorderColor('email'),
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '115%',
                  marginTop: '2px',
                },
                '& .MuiOutlinedInput-input': {
                  color: 'black !important', // Ensure label text is black
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '115%',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={blok.phone_label && blok.phone_label}
              variant='outlined'
              required={false}
              name='phone'
              type='number'
              value={formData.phone}
              onChange={handleChange}
              onFocus={() => handleFieldFocus('phone')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0,
                  color: 'black',
                  '& fieldset': {
                    borderColor: getFieldBorderColor('phone'),
                    borderWidth: 3,
                  },
                  '&:hover fieldset': {
                    borderColor: getFieldBorderColor('phone'),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: getFieldBorderColor('phone'),
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '115%',
                  marginTop: '2px',
                },
                '& .MuiOutlinedInput-input': {
                  color: 'black !important', // Ensure label text is black
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '115%',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant='outlined'
              required={false}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0,
                  '& fieldset': {
                    borderColor: getFieldBorderColor('job'),
                    borderWidth: 3,
                  },
                  '&:hover fieldset': {
                    borderColor: getFieldBorderColor('job'),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: getFieldBorderColor('job'),
                  },
                },
              }}
            >
              <InputLabel
                id='job-selection-label'
                sx={{
                  color: 'black !important',
                  '&.Mui-focused': {
                    color: 'black !important',
                  },
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '115%',
                  marginTop: '2px',
                }}
              >
                {blok.job_label && blok.job_label}
              </InputLabel>
              <Select
                labelId='job-selection-label'
                id='job-selection'
                name='job'
                value={formData.job}
                onChange={handleSelectChange}
                onFocus={() => handleFieldFocus('job')}
                label={blok.job_label && blok.job_label}
                sx={{
                  '& .MuiSelect-outlined': {
                    borderRadius: 0,
                    color: 'black',
                    borderColor: getFieldBorderColor('job'),
                    '& fieldset': {
                      borderColor: getFieldBorderColor('job'),
                      borderWidth: 3,
                    },
                    '&:hover fieldset': {
                      borderColor: getFieldBorderColor('job'),
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: getFieldBorderColor('job'),
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'black !important',
                  },
                  '& .MuiSelect-icon': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'black',
                    fontFamily: 'Soehne',
                    fontSize: {
                      xs: '12px', // Mobile
                      md: '18px', // Desktop
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '115%',
                  },
                }}
              >
                {blok.jobs &&
                  blok.jobs.map((job, index) => (
                    <MenuItem key={index} value={job.value}>
                      {job.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={4}
              label={blok.message_label && blok.message_label}
              variant='outlined'
              required={false}
              name='message'
              value={formData.message}
              onChange={handleChange}
              onFocus={() => handleFieldFocus('message')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0,
                  '& fieldset': {
                    borderColor: getFieldBorderColor('message'),
                    borderWidth: 3,
                  },
                  '&:hover fieldset': {
                    borderColor: getFieldBorderColor('message'),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: getFieldBorderColor('message'),
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '115%',
                },
                '& .MuiOutlinedInput-input': {
                  color: 'black !important', // Ensure label text is black
                  fontFamily: 'Soehne',
                  fontSize: {
                    xs: '12px', // Mobile
                    md: '18px', // Desktop
                  },
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '115%',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
              }}
            >
              <Grid item>
                <Grid container alignItems='center'>
                  <IconButton
                    color='primary'
                    aria-label='upload document'
                    component='label'
                    sx={{
                      color: 'black',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&:focus': {
                        backgroundColor: 'transparent',
                      },
                      '&:active': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiTouchRipple-root': {
                        display: 'none',
                      },
                      transition: 'none',
                    }}
                  >
                    <UploadFile sx={{ mr: 1 }} />
                    <Typography
                      variant='body2'
                      sx={{
                        fontFamily: 'Soehne',
                        fontSize: {
                          xs: '12px', // Mobile
                          md: '18px', // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '115%',
                      }}
                    >
                      {blok.upload_label}
                    </Typography>
                    <input
                      type='file'
                      accept='.pdf, .doc, .docx, .txt'
                      hidden
                      onChange={handleFileChange}
                    />
                  </IconButton>
                  {formData.fileName && (
                    <Typography
                      variant='body2'
                      sx={{
                        ml: 2,
                        fontFamily: 'Soehne',
                        fontSize: {
                          xs: '12px', // Mobile
                          md: '18px', // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '115%',
                      }}
                    >
                      {formData.fileName}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='privacy'
                      checked={formData.privacy}
                      onChange={handleCheckboxChange}
                      onFocus={() => handleFieldFocus('privacy')}
                      sx={{
                        color: getCheckboxColor('privacy'),
                        '&.Mui-checked': {
                          color: getCheckboxColor('privacy'),
                        },
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '&:focus': {
                          backgroundColor: 'transparent',
                        },
                        '&:active': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiTouchRipple-root': {
                          display: 'none',
                        },
                        transition: 'none',
                        paddingLeft: '20px',
                      }}
                    />
                  }
                  label={
                    <Link
                      href='/datenschutz'
                      target='_blank'
                      rel='noopener noreferrer'
                      sx={{
                        color: 'inherit', // Keeps the color the same as the surrounding text
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {blok.privacy_label && blok.privacy_label}
                    </Link>
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontFamily: 'Soehne',
                      fontSize: {
                        xs: '12px', // Mobile
                        md: '18px', // Desktop
                      },
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '115%',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              type='submit'
              label={blok.submit_label}
              customvariant='filled'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: 'Soehne',
                fontSize: {
                  xs: '12px', // Mobile
                  md: '18px', // Desktop
                },
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '115%',
              }}
            >
              {t('form.all_fields_mandatory')}
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Snackbar
            open={successMessage !== null}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              variant='filled'
              sx={{ width: '100%' }}
            >
              {successMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={error !== null}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleClose}
              severity='error'
              variant='filled'
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Container>
  );
};

export default ApplicationForm;
