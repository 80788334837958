import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import OptimizedImage from '../utils/OptimizedImage';
import { locales, defaultLocale } from '../translations/i18nConfig';

// Define the type for the Storyblok link object
export type StoryblokLink = {
  id: string;
  url: string;
  label: string;
  anchor: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
  target?: string;
};

// Define the type for the props of the ShowcaseItem component
type ShowcaseItemProps = {
  imageSrc: string;
  title?: string;
  link?: StoryblokLink;
};

// ShowcaseItem component to display each image in the slider
const ShowcaseItem: React.FC<ShowcaseItemProps> = React.memo(
  ({ imageSrc, title, link }) => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const currentLocale = locales.includes(pathSegments[0])
      ? pathSegments[0]
      : defaultLocale;

    // Adjust the link URL and make sure locale is correctly handled
    const getAdjustedUrl = useCallback(
      (url: string): string => {
        // Remove leading locale from the cached_url if already present
        const cleanedUrl = locales.some((locale) =>
          url.startsWith(`/${locale}`)
        )
          ? url.replace(`/${currentLocale}`, '') // Remove locale from the URL
          : url;

        // Add locale only if it's not the default locale
        return currentLocale === defaultLocale
          ? `/${cleanedUrl}`
          : `/${currentLocale}${cleanedUrl}`;
      },
      [currentLocale]
    );

    const adjustedUrl = link ? getAdjustedUrl(link.cached_url) : '';

    return (
      <div className='flex flex-col items-center justify-center cursor-pointer'>
        {link ? (
          <a
            href={adjustedUrl}
            target={link.target ?? '_self'}
            rel='noopener noreferrer'
          >
            <OptimizedImage
              filename={imageSrc}
              alt={title ?? 'Showcase Image'}
              loading='lazy'
              fetchpriority='low'
            />
          </a>
        ) : (
          <OptimizedImage
            filename={imageSrc}
            alt={title ?? 'Showcase Image'}
            loading='lazy'
            fetchpriority='low'
          />
        )}
      </div>
    );
  }
);

export default ShowcaseItem;
