import React, { useEffect, useRef } from 'react';

type OptimizedImageProps = {
  filename?: string;
  alt?: string;
  style?: React.CSSProperties;
  loading?: 'lazy' | 'eager';
  fetchpriority?: 'low' | 'high' | 'auto';
};

const OptimizedImage = ({
  filename,
  alt,
  style,
  loading,
  fetchpriority,
}: OptimizedImageProps) => {
  const optimizedUrl = `${filename}/m/filters:format(webp)`;
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current && fetchpriority) {
      imgRef.current.setAttribute('fetchpriority', fetchpriority);
    }
  }, [fetchpriority]);

  return (
    <img
      ref={imgRef}
      src={optimizedUrl}
      alt={alt}
      loading={loading}
      style={style}
    />
  );
};

export default OptimizedImage;
