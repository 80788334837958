import { storyblokEditable } from '@storyblok/react';

type PageTitleProps = {
  blok: {
    headline: string;
    alignment: 'center' | 'left';
    text_alignment: 'center' | 'left';
    max_width: number;
  };
};

const GenericTitle = ({ blok }: PageTitleProps) => {
  const justifyClass =
    blok.alignment === 'center' ? 'justify-center' : 'justify-left';
  const textAlignClass =
    blok.text_alignment === 'center' ? 'text-center' : 'text-left';

  return (
    <div
      {...storyblokEditable(blok)}
      className={`mx-auto container max-w-container flex justify-center ${justifyClass}`}
    >
      <div style={{ width: `${blok.max_width}px` }}>
        <h1
          className={`style-10-mobile tablet:style-10 mt-[163px] mb-8 uppercase ${textAlignClass}`}
        >
          {blok.headline}
        </h1>
      </div>
    </div>
  );
};

export default GenericTitle;
