import { storyblokEditable } from '@storyblok/react';

type TeaserProps = {
  blok: {
    headline: string;
  };
};

const Teaser = ({ blok }: TeaserProps) => {
  return (
    <h2
      className='py-32 text-hero-6xl font-respira text-center'
      {...storyblokEditable(blok)}
    >
      {blok.headline}
    </h2>
  );
};

export default Teaser;
