import React from 'react';
import Header from './Header';
import { ISbStoryData } from '@storyblok/react';
import Footer from './Footer';

const Layout = ({
  children,
  story,
  config,
}: {
  children: React.ReactNode;
  story: ISbStoryData;
  config: any;
}) => (
  <div>
    <Header config={config} />
    <main className='overflow-hidden'>{children}</main>
    <Footer config={config} />
  </div>
);

export default Layout;
